:root {
  --cyan:     '#7ED8FF';
  --magenta:  '#FF8CD1';
  --yellow:   '#FFD568';
}

@font-face {
  font-family: 'leagueSpartan';
  src: url(assets/fonts/LeagueSpartan-VariableFont_wght.ttf) format('truetype');
  font-weight: 1 999;
  font-stretch: 25% 151%;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: leagueSpartan, 'Oxygen', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 90vw;
  width: 99vw;
  max-width: 99vw;
  scrollbar-gutter: stable;
  
  background-image: url('./assets/asterisk.svg');
  background-size: auto auto;
  background-repeat: no-repeat; 
  background-position: top -70px right -70px;

  @media (max-width: 540px) {
    background-size: 70%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiMenu-paper {
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3) !important;
  border-radius: 0 !important;
  transform: scale(1, 1) !important;
  transition: opacity 200ms !important;
}

.Mui-focused {
  border: none !important;
  outline: none !important;
}

.MuiList-padding {
  padding: 0 !important;
}

.Mui-selected {
  background: #FFD568 !important;
}

.MuiInputBase-input:focus {
  background: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: linear-gradient(to bottom, #FFFFFF 80%, #D4D4D4);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to right bottom, #333333, #000000);
  box-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3);
}

.show-card {
  animation: fadeIn 400ms forwards;
}

.videoIcon::after {
  content: '';
  background: url('./assets/play_icon.svg');
  width: 70px;
  height: 70px;
  position: absolute;
}

.lazy {
  background-image: none;
  background-color: #D4D4D4;
  transition: all ease-in-out 400ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes goDown {
  0% {
    bottom: 2em;
  }
  100% {
    bottom: 0;
  }
}
